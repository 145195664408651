import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {DeviceType} from 'api/entities';
import {Observable, repeat} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {apiVersion} from '../../../../../alcedo/src/app/api-version.constant';
import {UserSessionService} from '../../../../../alcedo/src/app/shared/entities/auth/user-session.service';
import {getCacheHeaders} from '../../../../../alcedo/src/app/shared/entities/shared.service';
import {DataPoint} from './data-point.interface';
import {Device} from './device.interface';

type PublicDeviceTokenResponse = {access_token: string; expires_in: number; refresh_token: string; tokenType: string};

@Injectable({providedIn: 'root'})
export class DeviceService {
  public static readonly CO2_TYPES = ['CO2', 'CO2_FILTERED', 'CO2_ALL_SENSE'];
  public static readonly HUMIDITY_TYPES = ['HUMIDITY'];
  public static readonly TEMPERATURE_TYPES = ['TEMPERATURE', 'TEMPERATURE_ALT1', 'TEMPERATURE_ALT2'];
  public static readonly VOC_TYPES = ['VOC'];

  constructor(private http: HttpClient) {}

  /**
   * Copies all properties from a new device object to an existing device object except its data points and the live value subject that is
   * kept on the original object so that live values are not lost and subscribing to live values is still possible.
   * @param existingDevice Existing device whose object instance should be kept.
   * @param newDevice New device data that should be merged into the existing device object.
   */
  static mergeDevice(existingDevice: Device, newDevice: Device) {
    existingDevice.activated = newDevice.activated;
    existingDevice.activationCode = newDevice.activationCode;
    existingDevice.altitude = newDevice.altitude;
    existingDevice.batteryLevel = newDevice.batteryLevel;
    existingDevice.clientId = newDevice.clientId;
    existingDevice.clientPath = newDevice.clientPath;
    existingDevice.connectionState = newDevice.connectionState;
    existingDevice.deviceType = newDevice.deviceType;
    existingDevice.dtype = newDevice.dtype;
    existingDevice.id = newDevice.id;
    existingDevice.lastConnection = newDevice.lastConnection;
    existingDevice.name = newDevice.name;
    existingDevice.registrationDate = newDevice.registrationDate;
    existingDevice.schematicLink = newDevice.schematicLink;
    existingDevice.selfManaged = newDevice.selfManaged;
    existingDevice.serialNumber = newDevice.serialNumber;
    existingDevice.signalStrength = newDevice.signalStrength;

    if (!existingDevice.airQuality) {
      existingDevice.airQuality = {};
    }

    DeviceService.mergeDataPoints(existingDevice, newDevice);

    // liveValueSubject is not copied deliberately, since we are going to keep the existing subject.
  }

  static getDataPointByIoTType(device: Device, iotTypes: string[]): DataPoint {
    return device.dataPoints.find(dataPoint => iotTypes.indexOf(dataPoint.iotType) !== -1);
  }

  static isIoTSensor(device) {
    return (
      device &&
      (device.deviceType === DeviceType.IOT_WISELY_STANDARD ||
        device.deviceType === DeviceType.IOT_WISELY_ALLSENSE ||
        device.deviceType === DeviceType.IOT_WISELY_CARBONSENSE)
    );
  }

  private static mergeDataPoints(existingDevice: Device, newDevice: Device) {
    if (!existingDevice.dataPoints) {
      existingDevice.dataPoints = [];
    }

    newDevice.dataPoints.forEach(dataPoint => {
      const existingDataPoint = existingDevice.dataPoints.find(dp => dp.id === dataPoint.id);
      if (!existingDataPoint) {
        existingDevice.dataPoints.push(dataPoint);
      }
    });
  }

  getDevice(activationCode: string, cache?: boolean): Observable<Device> {
    return this.http.get('api/v1/360/no-auth/devices/' + activationCode, getCacheHeaders(cache)).pipe(
      map((result: Device) => {
        result.dataPoints.forEach(dataPoint => {
          dataPoint.history = dataPoint.history || [];
        });
        return result;
      })
    );
  }

  getData(activationCode: string): Observable<any> {
    return this.http.get('api/v1/360/no-auth/devices/' + activationCode + '/records');
  }

  loadDevices(clientId: number, cache?: boolean): Observable<Device[]> {
    return this.http.get<Device[]>('api/v1/360/clients/' + clientId + '/devices', getCacheHeaders(cache)).pipe(
      map((result: Device[]) => {
        result.forEach(device => device.dataPoints.forEach(dataPoint => (dataPoint.history = dataPoint.history || [])));
        return result;
      })
    );
  }

  updateDevice(requestPayload: Partial<Device>, deviceId: number): Observable<Device> {
    return this.http.put<Device>(apiVersion + 'devices/' + deviceId, requestPayload);
  }

  getPublicToken(): Observable<PublicDeviceTokenResponse> {
    return this.http.get<PublicDeviceTokenResponse>('api/v1/360/no-auth/devices/publicToken', getCacheHeaders(false)).pipe(
      repeat({delay: 1800 * 1000}),
      tap(r => UserSessionService.setToken(r.access_token))
    );
  }
}
