// Indicates which air quality attribute is currently the least healthy.
export enum AIR_QUALITY_STATUS {
  COMFORT = 0,
  VOC = 1,
  CO2 = 2
}

export enum HEALTH_SCORE {
  UNKNOWN = 0,
  HEALTHY = 1,
  UNHEALTHY = 2,
  CRITICAL = 3
}

export enum COMFORT_TYPE {
  COMFORTABLE = 'comfortable',
  STILL_COMFORTABLE = 'stillComfortable',
  UNCOMFORTABLE = 'uncomfortable',
  UNCOMFORTABLY_COLD = 'uncomfortablyCold',
  UNCOMFORTABLY_HOT = 'uncomfortablyHot',
  UNCOMFORTABLY_DRY = 'uncomfortablyDry',
  UNCOMFORTABLY_DAMP = 'uncomfortablyDamp'
}

export enum COMFORT_MESH {
  COMFORTABLE = 'comfortable',
  STILL_COMFORTABLE = 'stillComfortable',
  UNCOMFORTABLE = 'uncomfortable',
  UNCOMFORTABLY_COLD = 'cold',
  UNCOMFORTABLY_HOT = 'hot',
  UNCOMFORTABLY_DRY = 'dry',
  UNCOMFORTABLY_DAMP = 'damp'
}

export enum COMFORT_ICON {
  COMFORTABLE = 'comfort-comfortable',
  STILL_COMFORTABLE = 'comfort-still-comfortable',
  UNCOMFORTABLE = 'comfort-uncomfortable'
}

export interface ComfortArea {
  mesh: string;
  area: [number, number][];
  text: null;
  icon: string;
  healthScore: HEALTH_SCORE;
}

export interface AirQuality {
  // Specific measurements
  comfort?: ComfortArea;
  co2HealthScore?: HEALTH_SCORE;
  vocHealthScore?: HEALTH_SCORE;

  // Total air quality
  airQualityScore?: HEALTH_SCORE;
  airQualityIcon?: string;
  airQualityText?: string;
  airQualityStatus?: AIR_QUALITY_STATUS; // see AIR_QUALITY_STATUS
}
