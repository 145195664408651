export const THRESHOLD = {
  BATTERY_PERCENTAGE: {
    ChartDefaultMax: 100,
    ChartDefaultMin: 0,
    ComfortUpperLimit: 100,
    ComfortLowerLimit: 15,
    DangerUpperLimit: 100,
    DangerLowerLimit: 10
  },
  CO2: {
    ChartDefaultMax: 1400,
    ChartDefaultMin: 600,
    ComfortUpperLimit: 800,
    ComfortLowerLimit: 0,
    DangerUpperLimit: 1400,
    DangerLowerLimit: 0
  },
  CO2_ALL_SENSE: {
    ChartDefaultMax: 1400,
    ChartDefaultMin: 600,
    ComfortUpperLimit: 800,
    ComfortLowerLimit: 0,
    DangerUpperLimit: 1400,
    DangerLowerLimit: 0
  },
  CO2_FILTERED: {
    ChartDefaultMax: 1400,
    ChartDefaultMin: 600,
    ComfortUpperLimit: 800,
    ComfortLowerLimit: 0,
    DangerUpperLimit: 1400,
    DangerLowerLimit: 0
  },
  HUMIDITY: {
    ChartDefaultMax: 80,
    ChartDefaultMin: 20,
    ComfortUpperLimit: 65,
    ComfortLowerLimit: 40,
    DangerUpperLimit: 75,
    DangerLowerLimit: 35
  },
  IR_RAW: {
    ChartDefaultMax: 1100,
    ChartDefaultMin: 900,
    ComfortUpperLimit: 1100,
    ComfortLowerLimit: 900,
    DangerUpperLimit: 1100,
    DangerLowerLimit: 900
  },
  TEMPERATURE: {
    ChartDefaultMax: 28,
    ChartDefaultMin: 18,
    ComfortUpperLimit: 23.5,
    ComfortLowerLimit: 21,
    DangerUpperLimit: 25,
    DangerLowerLimit: 20
  },
  VOC: {
    ChartDefaultMax: 200,
    ChartDefaultMin: 0,
    ComfortUpperLimit: 50,
    ComfortLowerLimit: 0,
    DangerUpperLimit: 150,
    DangerLowerLimit: 0
  }
};
