import {polygonHull} from 'd3-polygon';
import {ComfortArea, COMFORT_ICON, COMFORT_MESH, COMFORT_TYPE, HEALTH_SCORE} from './air-quality.interface';

const comfortMidpoint: [number, number] = [22, 50];
const comfortMinTemp = -300;
const comfortMaxTemp = 300;
const comfortMinHum = 0;
const comfortMaxHum = 100;
const comfortTempRange = 12;

export const COMFORT_AREA: {[key: string]: ComfortArea} = {
  [COMFORT_TYPE.COMFORTABLE]: {
    mesh: COMFORT_MESH.COMFORTABLE,
    area: polygonHull([
      [20, 75],
      [21, 40],
      [25, 35],
      [23.5, 65]
    ]),
    text: null,
    icon: COMFORT_ICON.COMFORTABLE,
    healthScore: HEALTH_SCORE.HEALTHY
  },
  [COMFORT_TYPE.STILL_COMFORTABLE]: {
    mesh: COMFORT_MESH.STILL_COMFORTABLE,
    area: polygonHull([
      [19, 75],
      [20, 85],
      [22.5, 80],
      [25.5, 60],
      [26.5, 30],
      [26, 18],
      [21.5, 20],
      [20, 40]
    ]),
    text: null,
    icon: COMFORT_ICON.STILL_COMFORTABLE,
    healthScore: HEALTH_SCORE.UNHEALTHY
  },
  [COMFORT_TYPE.UNCOMFORTABLE]: {
    mesh: COMFORT_MESH.UNCOMFORTABLE,
    area: polygonHull([
      [comfortMinTemp, comfortMaxHum],
      [comfortMaxTemp, comfortMaxHum],
      [comfortMaxTemp, comfortMinHum],
      [comfortMinTemp, comfortMinHum]
    ]),
    text: null,
    icon: COMFORT_ICON.UNCOMFORTABLE,
    healthScore: HEALTH_SCORE.CRITICAL
  },
  [COMFORT_TYPE.UNCOMFORTABLY_COLD]: {
    mesh: COMFORT_MESH.UNCOMFORTABLY_COLD,
    area: polygonHull([
      comfortMidpoint,
      [comfortMidpoint[0] - comfortTempRange, comfortMinHum],
      [comfortMinTemp, comfortMinHum],
      [comfortMinTemp, comfortMaxHum],
      [comfortMidpoint[0] - comfortTempRange, comfortMaxHum]
    ]),
    text: null,
    icon: COMFORT_ICON.UNCOMFORTABLE,
    healthScore: HEALTH_SCORE.CRITICAL
  },
  [COMFORT_TYPE.UNCOMFORTABLY_HOT]: {
    mesh: COMFORT_MESH.UNCOMFORTABLY_HOT,
    area: polygonHull([
      comfortMidpoint,
      [comfortMidpoint[0] + comfortTempRange, comfortMaxHum],
      [comfortMaxTemp, comfortMaxHum],
      [comfortMaxTemp, comfortMinHum],
      [comfortMidpoint[0] + comfortTempRange, comfortMinHum]
    ]),
    text: null,
    icon: COMFORT_ICON.UNCOMFORTABLE,
    healthScore: HEALTH_SCORE.CRITICAL
  },
  [COMFORT_TYPE.UNCOMFORTABLY_DRY]: {
    mesh: COMFORT_MESH.UNCOMFORTABLY_DRY,
    area: polygonHull([
      comfortMidpoint,
      [comfortMidpoint[0] + comfortTempRange, comfortMinHum],
      [comfortMidpoint[0] - comfortTempRange, comfortMinHum]
    ]),
    text: null,
    icon: COMFORT_ICON.UNCOMFORTABLE,
    healthScore: HEALTH_SCORE.CRITICAL
  },
  [COMFORT_TYPE.UNCOMFORTABLY_DAMP]: {
    mesh: COMFORT_MESH.UNCOMFORTABLY_DAMP,
    area: polygonHull([
      comfortMidpoint,
      [comfortMidpoint[0] - comfortTempRange, comfortMaxHum],
      [comfortMidpoint[0] + comfortTempRange, comfortMaxHum]
    ]),
    text: null,
    icon: COMFORT_ICON.UNCOMFORTABLE,
    healthScore: HEALTH_SCORE.CRITICAL
  }
};
