import {Injectable} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {TranslocoService} from '@jsverse/transloco';
import {Observable, of} from 'rxjs';
import {DataPoint} from './data-point.interface';
import {AlcedoUnit} from './alcedo-units.interface';
import {AlcedoTypeIcons} from '../../../../../alcedo/src/app/shared/properties/alcedo-type-icons.constant';
import {AlcedoUnits} from '../../../../../alcedo/src/app/shared/properties/alcedo-units.constant';

@Injectable({providedIn: 'root'})
export class AlcedoUnitsService {
  constructor(
    private translate: TranslocoService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    this.registerIcon('comfort-comfortable', 'comfort-comfortable');
    this.registerIcon('comfort-still-comfortable', 'comfort-still-comfortable');
    this.registerIcon('comfort-uncomfortable', 'comfort-uncomfortable');
    this.registerIcon('quiz-character', 'quiz-character');
  }

  static getIcon(dataPoint: DataPoint): string {
    const unit = AlcedoUnitsService.getUnit(dataPoint);
    return (AlcedoTypeIcons[unit.alcedoTypeId] || '').replace('alcedo-icon-data-type-', '');
  }

  static getUnit(dataPoint: DataPoint): AlcedoUnit {
    // !!!WARNING using only alcedoTypeId, id and symbol -> no labels -> no unit label translation
    const unitId = dataPoint.unit;
    return AlcedoUnits[unitId];
  }

  // eslint-disable-next-line complexity
  getShortTypeName(dataPoint: DataPoint): Observable<string> {
    switch (dataPoint.iotType) {
      case 'ATMOSPHERIC_PRESSURE_ABSOLUTE':
      case 'ATMOSPHERIC_PRESSURE_AVERAGE':
        return this.translate.selectTranslate('PRESSURE');
      case 'CO2':
      case 'CO2_ALL_SENSE':
      case 'CO2_FILTERED':
        return this.translate.selectTranslate('CO2');
      case 'HUMIDITY':
        return this.translate.selectTranslate('HUMIDITY');
      case 'TEMPERATURE':
      case 'TEMPERATURE_ALT1':
      case 'TEMPERATURE_ALT2':
        return this.translate.selectTranslate('TEMPERATURE');
      case 'VOC':
        return this.translate.selectTranslate('VOC');
      default:
        return of(dataPoint.name);
    }
  }

  registerIcons(dataPoints: DataPoint[]) {
    dataPoints.forEach(dataPoint => {
      const icon = AlcedoUnitsService.getIcon(dataPoint);
      if (icon) {
        this.registerIcon(icon, 'data-type-' + icon);
      }
    });
  }

  private registerIcon(iconName: string, iconSVGName: string) {
    this.iconRegistry.addSvgIcon(iconName, this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg/' + iconSVGName + '.svg'));
  }
}
