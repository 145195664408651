import {Subject, Subscriber, Subscription} from 'rxjs';

export class QueueingSubject<T> extends Subject<T> {
  private queuedValues: T[] = [];

  next(value: T): void {
    if (this.closed || this.observed) {
      super.next(value);
    } else {
      this.queuedValues.push(value);
    }
  }

  /**
   * _subscribe is protected on the Subject, but is not exported as that in the type defs
   * rxjs defines it as internal, but we have no way around it since a queuing subject is not available any other way
   * @param subscriber
   */
  _subscribe(subscriber: Subscriber<T>): Subscription {
    // @ts-ignore
    const subscription = super._subscribe(subscriber);

    if (this.queuedValues.length) {
      this.queuedValues.forEach(value => super.next(value));
      this.queuedValues.splice(0);
    }

    return subscription;
  }
}
