import {Injectable} from '@angular/core';
import {TranslocoService} from '@jsverse/transloco';
import {DateTime} from 'luxon';
import {Subject} from 'rxjs';
import {PropertiesToId} from '../../../../../alcedo/src/app/shared/properties/properties-to-id.enum';
import {LiveValue, LiveValueService as LVService} from '../../../../../alcedo/src/app/shared/services/live-value/live-value.service';
import {AirQualityService} from './air-quality.service';
import {DataPoint} from './data-point.interface';
import {DataPointService} from './data-point.service';
import {Device} from './device.interface';
import {DeviceService} from './device.service';

@Injectable({providedIn: 'root'})
export class LiveValueService {
  private static defaultFormatter = new Intl.NumberFormat(navigator.language, {
    maximumFractionDigits: 1
  });

  constructor(
    private comfortService: AirQualityService,
    private deviceService: DeviceService,
    private translate: TranslocoService,
    private liveValueService: LVService
  ) {}

  static formatLiveValue(value, fractionSize: number): string {
    return LiveValueService.getFormatter(fractionSize).format(value);
  }

  static unregisterLiveValueUpdates(device: Device): void {
    if (device.liveValueSubject && !device.liveValueSubject.closed) {
      device.liveValueSubject.complete();
    }
    device.liveValueSubject = undefined;
    if (device.dataPoints) {
      device.dataPoints.forEach(dataPoint => {
        if (dataPoint.liveValueSubscription) {
          dataPoint.liveValueSubscription.unsubscribe();
          dataPoint.liveValueSubscription = undefined;
        }
      });
    }
  }

  static addLiveValueToHistory(dataPoint: DataPoint): void {
    // Add new live value to measurement history
    if (dataPoint.history && dataPoint.history[dataPoint.history.length - 1]) {
      if (dataPoint.current.timestamp !== dataPoint.history[dataPoint.history.length - 1].t) {
        if (DataPointService.isEnergy(dataPoint)) {
          const newDay =
            DateTime.fromMillis(dataPoint.current.timestamp).startOf('day').toMillis() >
            DateTime.fromMillis(dataPoint.history[dataPoint.history.length - 1].t)
              .endOf('day')
              .toMillis();
          // check if the new live value is in a new day if true do not remove the last value in history records
          if (!newDay) {
            dataPoint.history.pop();
          }
          dataPoint.current.timestamp = DateTime.local().startOf('day').toMillis();
        }
        dataPoint.history.push({
          v: dataPoint.current.value,
          t: dataPoint.current.timestamp
        });
        dataPoint.history = dataPoint.history.concat([]);
      }
    }
  }

  private static getFormatter(fractionSize: number): Intl.NumberFormat {
    let formatter: Intl.NumberFormat = LiveValueService.defaultFormatter;
    if (fractionSize) {
      formatter = new Intl.NumberFormat(navigator.language, {
        maximumFractionDigits: fractionSize,
        minimumFractionDigits: fractionSize
      });
    }
    return formatter;
  }

  registerLiveValueUpdates(device: Device): Subject<Device> {
    if (!device.liveValueSubject) {
      device.liveValueSubject = new Subject<Device>();
      this.deviceService.getDevice(device.activationCode, true).subscribe(dev => {
        device.lastConnection = dev.lastConnection;
      });
      device.dataPoints.forEach(dataPoint => {
        let propertyId: number;
        if (DataPointService.isEnergy(dataPoint)) {
          propertyId = PropertiesToId.Current_Day_Consumption;
        } else {
          propertyId = PropertiesToId.Present_Value;
        }
        dataPoint.liveValueSubscription = this.liveValueService
          .getObservable({
            dataPointId: dataPoint.id,
            propertyId
          })
          .subscribe(message => this.nextLiveValue(device, dataPoint, message));
      });
    }
    return device.liveValueSubject;
  }

  nextLiveValue(device: Device, dataPoint: DataPoint, lv: LiveValue): void {
    dataPoint.current = {
      value: lv.value,
      timestamp: lv.timestamp,
      info: this.getLiveValueInfo(dataPoint, lv)
    };

    LiveValueService.addLiveValueToHistory(dataPoint);

    dataPoint.currentHealthScore = AirQualityService.getDataPointHealthScore(dataPoint);

    // Calculate comfort
    if (DeviceService.isIoTSensor(device)) {
      this.comfortService.determineAirQuality(device);
    }

    device.liveValueSubject.next(device);
  }

  getLiveValueInfo(dataPoint: DataPoint, liveValue: LiveValue): string {
    if (DeviceService.VOC_TYPES.indexOf(dataPoint.iotType) !== -1) {
      // Convert VOC value into text
      if (liveValue.value > 350) {
        return this.translate.translate('IAQ_EXTREME_EXPOSURE');
      } else if (liveValue.value > 250) {
        return this.translate.translate('IAQ_SERIOUS_EXPOSURE');
      } else if (liveValue.value > 200) {
        return this.translate.translate('IAQ_HEAVY_EXPOSURE');
      } else if (liveValue.value > 150) {
        return this.translate.translate('IAQ_MODERATE_EXPOSURE');
      } else if (liveValue.value > 100) {
        return this.translate.translate('IAQ_LIGHT_EXPOSURE');
      } else if (liveValue.value > 50) {
        return this.translate.translate('IAQ_GOOD');
      } else {
        return this.translate.translate('IAQ_EXCELLENT');
      }
    }
  }
}
