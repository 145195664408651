import {BacnetProperties} from './bacnet/bacnet-properties.enum';

export enum PropertiesToId {
  IN_ALARM = BacnetProperties.STATUS_FLAGS,
  Out_Of_Service = BacnetProperties.OUT_OF_SERVICE,
  OUT_OF_SERVICE = BacnetProperties.OUT_OF_SERVICE,
  ALCEDO_ALARM_STATE = BacnetProperties.STATUS_FLAGS,
  Overridden = BacnetProperties.STATUS_FLAGS,
  Fault = BacnetProperties.STATUS_FLAGS,
  Accepted_Modes = BacnetProperties.ACCEPTED_MODES,
  Acked_Transitions = BacnetProperties.ACKED_TRANSITIONS,
  Ack_Required = BacnetProperties.ACK_REQUIRED,
  Action = BacnetProperties.ACTION,
  Action_Text = BacnetProperties.ACTION_TEXT,
  Active_Text = BacnetProperties.ACTIVE_TEXT,
  Active_Vt_Sessions = BacnetProperties.ACTIVE_VT_SESSIONS,
  Active_Cov_Subscriptions = BacnetProperties.ACTIVE_COV_SUBSCRIPTIONS,
  Adjust_Value = BacnetProperties.ADJUST_VALUE,
  Alarm_Value = BacnetProperties.ALARM_VALUE,
  Alarm_Values = BacnetProperties.ALARM_VALUES,
  Align_Intervals = BacnetProperties.ALIGN_INTERVALS,
  All = BacnetProperties.ALL,
  All_Writes_Successful = BacnetProperties.ALL_WRITES_SUCCESSFUL,
  Apdu_Segment_Timeout = BacnetProperties.APDU_SEGMENT_TIMEOUT,
  Apdu_Timeout = BacnetProperties.APDU_TIMEOUT,
  Application_Software_Version = BacnetProperties.APPLICATION_SOFTWARE_VERSION,
  Archive = BacnetProperties.ARCHIVE,
  Attempted_Samples = BacnetProperties.ATTEMPTED_SAMPLES,
  Average_Value = BacnetProperties.AVERAGE_VALUE,
  Backup_Failure_Timeout = BacnetProperties.BACKUP_FAILURE_TIMEOUT,
  Bias = BacnetProperties.BIAS,
  Buffer_Size = BacnetProperties.BUFFER_SIZE,
  Change_Of_State_Count = BacnetProperties.CHANGE_OF_STATE_COUNT,
  Change_Of_State_Time = BacnetProperties.CHANGE_OF_STATE_TIME,
  Notification_Class = BacnetProperties.NOTIFICATION_CLASS,
  Client_Cov_Increment = BacnetProperties.CLIENT_COV_INCREMENT,
  Configuration_Files = BacnetProperties.CONFIGURATION_FILES,
  Controlled_Variable_Reference = BacnetProperties.CONTROLLED_VARIABLE_REFERENCE,
  Controlled_Variable_Units = BacnetProperties.CONTROLLED_VARIABLE_UNITS,
  Controlled_Variable_Value = BacnetProperties.CONTROLLED_VARIABLE_VALUE,
  Count = BacnetProperties.COUNT,
  Count_Before_Change = BacnetProperties.COUNT_BEFORE_CHANGE,
  Count_Change_Time = BacnetProperties.COUNT_CHANGE_TIME,
  Cov_Increment = BacnetProperties.COV_INCREMENT,
  Cov_Period = BacnetProperties.COV_PERIOD,
  Cov_Resubscription_Interval = BacnetProperties.COV_RESUBSCRIPTION_INTERVAL,
  Current_Notify_Time = BacnetProperties.CURRENT_NOTIFY_TIME,
  Database_Revision = BacnetProperties.DATABASE_REVISION,
  Date_List = BacnetProperties.DATE_LIST,
  Daylight_Savings_Status = BacnetProperties.DAYLIGHT_SAVINGS_STATUS,
  Deadband = BacnetProperties.DEADBAND,
  Derivative_Constant = BacnetProperties.DERIVATIVE_CONSTANT,
  Derivative_Constant_Units = BacnetProperties.DERIVATIVE_CONSTANT_UNITS,
  Description = BacnetProperties.DESCRIPTION,
  Description_Of_Halt = BacnetProperties.DESCRIPTION_OF_HALT,
  Device_Address_Binding = BacnetProperties.DEVICE_ADDRESS_BINDING,
  Device_Type = BacnetProperties.DEVICE_TYPE,
  Direct_Reading = BacnetProperties.DIRECT_READING,
  Effective_Period = BacnetProperties.EFFECTIVE_PERIOD,
  Elapsed_Active_Time = BacnetProperties.ELAPSED_ACTIVE_TIME,
  Enable = BacnetProperties.ENABLE,
  Error_Limit = BacnetProperties.ERROR_LIMIT,
  Event_Algorithm_Inhibit = BacnetProperties.EVENT_ALGORITHM_INHIBIT,
  Event_Detection_Enable = BacnetProperties.EVENT_DETECTION_ENABLE,
  Event_Enable = BacnetProperties.EVENT_ENABLE,
  Event_Parameters = BacnetProperties.EVENT_PARAMETERS,
  Event_State = BacnetProperties.EVENT_STATE,
  Event_Time_Stamps = BacnetProperties.EVENT_TIME_STAMPS,
  Event_Type = BacnetProperties.EVENT_TYPE,
  Exception_Schedule = BacnetProperties.EXCEPTION_SCHEDULE,
  Fault_Values = BacnetProperties.FAULT_VALUES,
  Feedback_Value = BacnetProperties.FEEDBACK_VALUE,
  File_Access_Method = BacnetProperties.FILE_ACCESS_METHOD,
  File_Size = BacnetProperties.FILE_SIZE,
  File_Type = BacnetProperties.FILE_TYPE,
  Firmware_Revision = BacnetProperties.FIRMWARE_REVISION,
  High_Limit = BacnetProperties.HIGH_LIMIT,
  Inactive_Text = BacnetProperties.INACTIVE_TEXT,
  In_Process = BacnetProperties.IN_PROCESS,
  Input_Reference = BacnetProperties.INPUT_REFERENCE,
  Instance_Of = BacnetProperties.INSTANCE_OF,
  Integral_Constant = BacnetProperties.INTEGRAL_CONSTANT,
  Integral_Constant_Units = BacnetProperties.INTEGRAL_CONSTANT_UNITS,
  Interval_Offset = BacnetProperties.INTERVAL_OFFSET,
  Issue_Confirmed_Notifications = BacnetProperties.ISSUE_CONFIRMED_NOTIFICATIONS,
  Last_Notify_Record = BacnetProperties.LAST_NOTIFY_RECORD,
  Last_Restart_Reason = BacnetProperties.LAST_RESTART_REASON,
  Last_Restore_Time = BacnetProperties.LAST_RESTORE_TIME,
  Life_Safety_Alarm_Values = BacnetProperties.LIFE_SAFETY_ALARM_VALUES,
  Limit_Enable = BacnetProperties.LIMIT_ENABLE,
  Limit_Monitoring_Interval = BacnetProperties.LIMIT_MONITORING_INTERVAL,
  List_Of_Group_Members = BacnetProperties.LIST_OF_GROUP_MEMBERS,
  List_Of_Object_References = BacnetProperties.LIST_OF_OBJECT_PROPERTY_REFERENCES,
  List_Of_Session_Keys = BacnetProperties.LIST_OF_SESSION_KEYS,
  '<LiveValue>' = BacnetProperties.PRESENT_VALUE,
  '<LiveValue&Unit>' = BacnetProperties.PRESENT_VALUE,
  Local_Date = BacnetProperties.LOCAL_DATE,
  Local_Time = BacnetProperties.LOCAL_TIME,
  Location = BacnetProperties.LOCATION,
  Log_Buffer = BacnetProperties.LOG_BUFFER,
  Log_Device_Object_Property = BacnetProperties.LOG_DEVICE_OBJECT_PROPERTY,
  Log_Interval = BacnetProperties.LOG_INTERVAL,
  Logging_Object = BacnetProperties.LOGGING_OBJECT,
  Logging_Record = BacnetProperties.LOGGING_RECORD,
  Logging_Type = BacnetProperties.LOGGING_TYPE,
  Low_Limit = BacnetProperties.LOW_LIMIT,
  Maintenance_Required = BacnetProperties.MAINTENANCE_REQUIRED,
  Manipulated_Variable_Reference = BacnetProperties.MANIPULATED_VARIABLE_REFERENCE,
  Maximum_Output = BacnetProperties.MAXIMUM_OUTPUT,
  Maximum_Value = BacnetProperties.MAXIMUM_VALUE,
  Maximum_Value_Timestamp = BacnetProperties.MAXIMUM_VALUE_TIMESTAMP,
  Max_Apdu_Length_Accepted = BacnetProperties.MAX_APDU_LENGTH_ACCEPTED,
  Max_Info_Frames = BacnetProperties.MAX_INFO_FRAMES,
  Max_Master = BacnetProperties.MAX_MASTER,
  Max_Pres_Value = BacnetProperties.MAX_PRES_VALUE,
  Max_Segments_Accepted = BacnetProperties.MAX_SEGMENTS_ACCEPTED,
  Member_Of = BacnetProperties.MEMBER_OF,
  Member_Status_Flags = BacnetProperties.MEMBER_STATUS_FLAGS,
  Minimum_Off_Time = BacnetProperties.MINIMUM_OFF_TIME,
  Minimum_On_Time = BacnetProperties.MINIMUM_ON_TIME,
  Minimum_Output = BacnetProperties.MINIMUM_OUTPUT,
  Minimum_Value = BacnetProperties.MINIMUM_VALUE,
  Minimum_Value_Timestamp = BacnetProperties.MINIMUM_VALUE_TIMESTAMP,
  Min_Pres_Value = BacnetProperties.MIN_PRES_VALUE,
  Mode = BacnetProperties.MODE,
  Model_Name = BacnetProperties.MODEL_NAME,
  Modification_Date = BacnetProperties.MODIFICATION_DATE,
  '<MultistateText>' = BacnetProperties.PRESENT_VALUE,
  Notification_Threshold = BacnetProperties.NOTIFICATION_THRESHOLD,
  Notify_Type = BacnetProperties.NOTIFY_TYPE,
  Number_Of_Apdu_Retries = BacnetProperties.NUMBER_OF_APDU_RETRIES,
  Number_Of_States = BacnetProperties.NUMBER_OF_STATES,
  OPC_UA_Node_Class = 1071,
  OPC_UA_Parent_Node_Id = 1072,
  Object_Identifier = BacnetProperties.OBJECT_IDENTIFIER,
  Object_List = BacnetProperties.OBJECT_LIST,
  Object_Name = BacnetProperties.OBJECT_NAME,
  Object_Property_Reference = BacnetProperties.OBJECT_PROPERTY_REFERENCE,
  Object_Type = BacnetProperties.OBJECT_TYPE,
  Operation_Expected = BacnetProperties.OPERATION_EXPECTED,
  Optional = BacnetProperties.OPTIONAL,
  Output_Units = BacnetProperties.OUTPUT_UNITS,
  Polarity = BacnetProperties.POLARITY,
  Prescale = BacnetProperties.PRESCALE,
  Present_Value = BacnetProperties.PRESENT_VALUE,
  Previous_Notify_Time = BacnetProperties.PREVIOUS_NOTIFY_TIME,
  Protocol_Revision = BacnetProperties.PROTOCOL_REVISION,
  Priority = BacnetProperties.PRIORITY,
  Priority_Array = BacnetProperties.PRIORITY_ARRAY,
  Priority_For_Writing = BacnetProperties.PRIORITY_FOR_WRITING,
  Process_Identifier = BacnetProperties.PROCESS_IDENTIFIER,
  Profile_Name = BacnetProperties.PROFILE_NAME,
  Program_Change = BacnetProperties.PROGRAM_CHANGE,
  Program_Location = BacnetProperties.PROGRAM_LOCATION,
  Program_State = BacnetProperties.PROGRAM_STATE,
  Proportional_Constant = BacnetProperties.PROPORTIONAL_CONSTANT,
  Proportional_Constant_Units = BacnetProperties.PROPORTIONAL_CONSTANT_UNITS,
  Protocol_Conformance_Class = BacnetProperties.PROTOCOL_CONFORMANCE_CLASS,
  Protocol_Object_Types_Supported = BacnetProperties.PROTOCOL_OBJECT_TYPES_SUPPORTED,
  Protocol_Services_Supported = BacnetProperties.PROTOCOL_SERVICES_SUPPORTED,
  Protocol_Version = BacnetProperties.PROTOCOL_VERSION,
  Pulse_Rate = BacnetProperties.PULSE_RATE,
  Read_Only = BacnetProperties.READ_ONLY,
  Reason_For_Halt = BacnetProperties.REASON_FOR_HALT,
  Recipient = BacnetProperties.RECIPIENT,
  Recipient_List = BacnetProperties.RECIPIENT_LIST,
  Records_Since_Notification = BacnetProperties.RECORDS_SINCE_NOTIFICATION,
  Record_Count = BacnetProperties.RECORD_COUNT,
  Reliability = BacnetProperties.RELIABILITY,
  Relinquish_Default = BacnetProperties.RELINQUISH_DEFAULT,
  Required = BacnetProperties.REQUIRED,
  Resolution = BacnetProperties.RESOLUTION,
  Restart_Notification_Recipients = BacnetProperties.RESTART_NOTIFICATION_RECIPIENTS,
  Scale = BacnetProperties.SCALE,
  Scale_Factor = BacnetProperties.SCALE_FACTOR,
  Schedule_Default = BacnetProperties.SCHEDULE_DEFAULT,
  Segmentation_Supported = BacnetProperties.SEGMENTATION_SUPPORTED,
  Setpoint = BacnetProperties.SETPOINT,
  Setpoint_Reference = BacnetProperties.SETPOINT_REFERENCE,
  Setting = BacnetProperties.SETTING,
  Silenced = BacnetProperties.SILENCED,
  Start_Time = BacnetProperties.START_TIME,
  State_Text = BacnetProperties.STATE_TEXT,
  Status_Flags = BacnetProperties.STATUS_FLAGS,
  Stop_Time = BacnetProperties.STOP_TIME,
  Stop_When_Full = BacnetProperties.STOP_WHEN_FULL,
  System_Status = BacnetProperties.SYSTEM_STATUS,
  Time_Delay = BacnetProperties.TIME_DELAY,
  Time_Of_Active_Time_Reset = BacnetProperties.TIME_OF_ACTIVE_TIME_RESET,
  Time_Of_Device_Restart = BacnetProperties.TIME_OF_DEVICE_RESTART,
  Time_Of_State_Count_Reset = BacnetProperties.TIME_OF_STATE_COUNT_RESET,
  Time_Synchronization_Interval = BacnetProperties.TIME_SYNCHRONIZATION_INTERVAL,
  Time_Synchronization_Recipients = BacnetProperties.TIME_SYNCHRONIZATION_RECIPIENTS,
  Total_Record_Count = BacnetProperties.TOTAL_RECORD_COUNT,
  Tracking_Value = BacnetProperties.TRACKING_VALUE,
  Trigger = BacnetProperties.TRIGGER,
  Units = BacnetProperties.UNITS,
  Update_Interval = BacnetProperties.UPDATE_INTERVAL,
  Update_Time = BacnetProperties.UPDATE_TIME,
  Utc_Offset = BacnetProperties.UTC_OFFSET,
  Utc_Time_Synchronization_Recipients = BacnetProperties.UTC_TIME_SYNCHRONIZATION_RECIPIENTS,
  Valid_Samples = BacnetProperties.VALID_SAMPLES,
  Value_Before_Change = BacnetProperties.VALUE_BEFORE_CHANGE,
  Value_Set = BacnetProperties.VALUE_SET,
  Value_Change_Time = BacnetProperties.VALUE_CHANGE_TIME,
  Variance_Value = BacnetProperties.VARIANCE_VALUE,
  Vendor_Identifier = BacnetProperties.VENDOR_IDENTIFIER,
  Vendor_Name = BacnetProperties.VENDOR_NAME,
  Vt_Classes_Supported = BacnetProperties.VT_CLASSES_SUPPORTED,
  Wago_Alarm_State = 1070,
  Weekly_Schedule = BacnetProperties.WEEKLY_SCHEDULE,
  Window_Interval = BacnetProperties.WINDOW_INTERVAL,
  Window_Samples = BacnetProperties.WINDOW_SAMPLES,
  Zone_Members = BacnetProperties.ZONE_MEMBERS,
  Node_Subtype = BacnetProperties.NODE_SUBTYPE,
  Node_Type = BacnetProperties.NODE_TYPE,
  Subordinate_Annotations = BacnetProperties.SUBORDINATE_ANNOTATIONS,
  Subordinate_List = BacnetProperties.SUBORDINATE_LIST,
  Actual_Shed_Level = BacnetProperties.ACTUAL_SHED_LEVEL,
  Duty_Window = BacnetProperties.DUTY_WINDOW,
  Expected_Shed_Level = BacnetProperties.EXPECTED_SHED_LEVEL,
  Full_Duty_Baseline = BacnetProperties.FULL_DUTY_BASELINE,
  Requested_Shed_Level = BacnetProperties.REQUESTED_SHED_LEVEL,
  Shed_Duration = BacnetProperties.SHED_DURATION,
  Shed_Level_Descriptions = BacnetProperties.SHED_LEVEL_DESCRIPTIONS,
  Shed_Levels = BacnetProperties.SHED_LEVELS,
  State_Description = BacnetProperties.STATE_DESCRIPTION,
  Door_Alarm_State = BacnetProperties.DOOR_ALARM_STATE,
  Door_Extended_Pulse_Time = BacnetProperties.DOOR_EXTENDED_PULSE_TIME,
  Door_Members = BacnetProperties.DOOR_MEMBERS,
  Door_Open_Too_Long_Time = BacnetProperties.DOOR_OPEN_TOO_LONG_TIME,
  Door_Pulse_Time = BacnetProperties.DOOR_PULSE_TIME,
  Door_Status = BacnetProperties.DOOR_STATUS,
  Door_Unlock_Delay_Time = BacnetProperties.DOOR_UNLOCK_DELAY_TIME,
  Lock_Status = BacnetProperties.LOCK_STATUS,
  Masked_Alarm_Values = BacnetProperties.MASKED_ALARM_VALUES,
  Secured_Status = BacnetProperties.SECURED_STATUS,
  Current_Day_Consumption = 1069
}
