export const AIR_QUALITY_TEXT = {
  // Keys inside these objects are the corresponding values of HEALTH_SCORE.
  CO2: {
    1: 'CO2_LEVEL_NORMAL',
    2: 'CO2_LEVEL_ELEVATED',
    3: 'CO2LEVEL_HIGH'
  },
  VOC: {
    1: 'VOC_LEVEL_NORMAL',
    2: 'VOC_LEVEL_ELEVATED',
    3: 'VOC_LEVEL_HIGH'
  }
};
