import {AlcedoUnitsService} from './alcedo-units.service';
import {DataPoint} from './data-point.interface';
import {DeviceService} from './device.service';

export class DataPointService {
  static isCO2(dataPoint: DataPoint) {
    return DataPointService.checkDataPointType(dataPoint.iotType, DeviceService.CO2_TYPES);
  }

  static isEnergy(dataPoint: DataPoint) {
    const unit = AlcedoUnitsService.getUnit(dataPoint);
    return unit && unit.alcedoTypeId === 20;
  }

  static isHumidity(dataPoint: DataPoint) {
    return DataPointService.checkDataPointType(dataPoint.iotType, DeviceService.HUMIDITY_TYPES);
  }

  static isTemperature(dataPoint: DataPoint) {
    return DataPointService.checkDataPointType(dataPoint.iotType, DeviceService.TEMPERATURE_TYPES);
  }

  static isVOC(dataPoint: DataPoint) {
    return DataPointService.checkDataPointType(dataPoint.iotType, DeviceService.VOC_TYPES);
  }

  private static checkDataPointType(iotType: string, types: string[]) {
    return types.indexOf(iotType) !== -1;
  }
}
