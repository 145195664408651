import {AlcedoTypes} from './alcedo-types.enum';

export const AlcedoTypeIcons = {
  get [AlcedoTypes.ACCELERATION]() {
    return 'alcedo-icon-data-type-acceleration';
  },
  get [AlcedoTypes.ANGLE]() {
    return 'alcedo-icon-data-type-angle';
  },
  get [AlcedoTypes.ANGULAR_MOMENTUM]() {
    return 'alcedo-icon-data-type-angular-momentum';
  },
  get [AlcedoTypes.AREA]() {
    return 'alcedo-icon-data-type-area';
  },
  get [AlcedoTypes.BOOLEAN]() {
    return 'alcedo-icon-data-type-boolean';
  },
  get [AlcedoTypes.CAPACITANCE]() {
    return 'alcedo-icon-data-type-capacitance';
  },
  get [AlcedoTypes.COMPRESSIBILITY]() {
    return 'alcedo-icon-data-type-compressibility';
  },
  get [AlcedoTypes.CONDUCTANCE]() {
    return 'alcedo-icon-data-type-conductance';
  },
  get [AlcedoTypes.CONDUCTIVITY]() {
    return 'alcedo-icon-data-type-conductivity';
  },
  get [AlcedoTypes.COOLING_RATE]() {
    return 'alcedo-icon-data-type-cooling-rate';
  },
  get [AlcedoTypes.CURRENCY]() {
    return 'alcedo-icon-data-type-currency';
  },
  get [AlcedoTypes.CURRENT]() {
    return 'alcedo-icon-data-type-current';
  },
  get [AlcedoTypes.CURRENT_DENSITY]() {
    return 'alcedo-icon-data-type-current-density';
  },
  get [AlcedoTypes.DATA_TRANSFER_SPEED]() {
    return 'alcedo-icon-data-type-data-transfer-speed';
  },
  get [AlcedoTypes.DATA_VOLUME]() {
    return 'alcedo-icon-data-type-data-volume';
  },
  get [AlcedoTypes.DENSITY]() {
    return 'alcedo-icon-data-type-density';
  },
  get [AlcedoTypes.DISTANCE]() {
    return 'alcedo-icon-data-type-distance';
  },
  get [AlcedoTypes.DOUBLE]() {
    return 'alcedo-icon-data-type-double';
  },
  get [AlcedoTypes.ELECTRIC_FIELD_STRENGTH]() {
    return 'alcedo-icon-data-type-electric-field-strength';
  },
  get [AlcedoTypes.ENERGY]() {
    return 'alcedo-icon-data-type-energy';
  },
  get [AlcedoTypes.ENTROPY]() {
    return 'alcedo-icon-data-type-entropy';
  },
  get [AlcedoTypes.FLOAT]() {
    return 'alcedo-icon-data-type-float';
  },
  get [AlcedoTypes.FORCE]() {
    return 'alcedo-icon-data-type-force';
  },
  get [AlcedoTypes.FREQUENCY]() {
    return 'alcedo-icon-data-type-frequency';
  },
  get [AlcedoTypes.ILLUMINANCE]() {
    return 'alcedo-icon-data-type-illuminance';
  },
  get [AlcedoTypes.INDUCTANCE]() {
    return 'alcedo-icon-data-type-inductance';
  },
  get [AlcedoTypes.INSOLATION]() {
    return 'alcedo-icon-data-type-insolation';
  },
  get [AlcedoTypes.INTEGER]() {
    return 'alcedo-icon-data-type-integer';
  },
  get [AlcedoTypes.IRRADIANCE]() {
    return 'alcedo-icon-data-type-irradiance';
  },
  get [AlcedoTypes.LONG]() {
    return 'alcedo-icon-data-type-long';
  },
  get [AlcedoTypes.LUMINANCE]() {
    return 'alcedo-icon-data-type-luminance';
  },
  get [AlcedoTypes.LUMINOUS_FLUX]() {
    return 'alcedo-icon-data-type-luminous-flux';
  },
  get [AlcedoTypes.LUMINOUS_INTENSITY]() {
    return 'alcedo-icon-data-type-luminous-intensity';
  },
  get [AlcedoTypes.MAGNETIC_FIELD_STRENGTH]() {
    return 'alcedo-icon-data-type-magnetic-field-strength';
  },
  get [AlcedoTypes.MAGNETIC_FLUX]() {
    return '';
  },
  get [AlcedoTypes.MAGNETIC_FLUX_DENSITY]() {
    return '';
  },
  get [AlcedoTypes.MAGNETIC_MOMENT]() {
    return 'alcedo-icon-data-type-magnetic-moment';
  },
  get [AlcedoTypes.MASS]() {
    return 'alcedo-icon-data-type-mass';
  },
  get [AlcedoTypes.MASS_FLOW]() {
    return 'alcedo-icon-data-type-mass-flow';
  },
  get [AlcedoTypes.MOMENTUM]() {
    return 'alcedo-icon-data-type-momentum';
  },
  get [AlcedoTypes.NONE]() {
    return '';
  },
  get [AlcedoTypes.NULL]() {
    return '';
  },
  get [AlcedoTypes.RATIO]() {
    return 'alcedo-icon-data-type-percent';
  },
  get [AlcedoTypes.POWER]() {
    return 'alcedo-icon-data-type-power';
  },
  get [AlcedoTypes.POWER_LEVEL]() {
    return 'alcedo-icon-data-type-power-level';
  },
  get [AlcedoTypes.PRESSURE]() {
    return 'alcedo-icon-data-type-pressure';
  },
  get [AlcedoTypes.RELATIVE_HUMIDITY]() {
    return 'alcedo-icon-data-type-relative-humidity';
  },
  get [AlcedoTypes.RESISTANCE]() {
    return 'alcedo-icon-data-type-resistance';
  },
  get [AlcedoTypes.RESISTIVITY]() {
    return 'alcedo-icon-data-type-resistivity';
  },
  get [AlcedoTypes.SPECIFIC_ENERGY]() {
    return '';
  },
  get [AlcedoTypes.SPECIFIC_HEAT]() {
    return 'alcedo-icon-data-type-specific-heat';
  },
  get [AlcedoTypes.STRING]() {
    return 'alcedo-icon-data-type-string';
  },
  get [AlcedoTypes.SURFACE_TENSION]() {
    return 'alcedo-icon-data-type-surface-tension';
  },
  get [AlcedoTypes.TEMPERATURE]() {
    return 'alcedo-icon-data-type-temperature';
  },
  get [AlcedoTypes.TEMPERATURE_DEPARTURE]() {
    return 'alcedo-icon-data-type-temperature-departure';
  },
  get [AlcedoTypes.TEMPERATURE_DIFFERENCE]() {
    return 'alcedo-icon-data-type-temperature-difference';
  },
  get [AlcedoTypes.THERMAL_CONDUCTIVITY]() {
    return 'alcedo-icon-data-type-thermal-conductivity';
  },
  get [AlcedoTypes.THERMAL_TRANSMITTANCE]() {
    return 'alcedo-icon-data-type-thermal-transmittance';
  },
  get [AlcedoTypes.THOMSON_COEFFICIENT]() {
    return 'alcedo-icon-data-type-thomson-coefficient';
  },
  get [AlcedoTypes.TIME]() {
    return 'alcedo-icon-data-type-time';
  },
  get [AlcedoTypes.TIMESPAN]() {
    return 'alcedo-icon-data-type-time-span';
  },
  get [AlcedoTypes.UNSIGNED_INTEGER]() {
    return 'alcedo-icon-data-type-unsigned-integer';
  },
  get [AlcedoTypes.UNSIGNED_LONG]() {
    return 'alcedo-icon-data-type-unsigned-long';
  },
  get [AlcedoTypes.VELOCITY]() {
    return 'alcedo-icon-data-type-velocity';
  },
  get [AlcedoTypes.VOLTAGE]() {
    return 'alcedo-icon-data-type-voltage';
  },
  get [AlcedoTypes.VOLUME]() {
    return 'alcedo-icon-data-type-volume';
  },
  get [AlcedoTypes.VOLUME_CURRENT]() {
    return 'alcedo-icon-data-type-volume-current';
  },
  get [AlcedoTypes.ABSOLUTE_HUMIDITY]() {
    return '';
  },
  get [AlcedoTypes.ALARM_ANALYSIS]() {
    return '';
  },
  get [AlcedoTypes.DECIBEL]() {
    return '';
  },
  get [AlcedoTypes.VOLTAGE_LEVEL]() {
    return '';
  },
  get [AlcedoTypes.RADIATION_ACTIVITY]() {
    return '';
  },
  get [AlcedoTypes.RADIATION_ABSORBED_DOSE]() {
    return '';
  },
  get [AlcedoTypes.RADIATION_EQUIVALENT_DOSE]() {
    return '';
  },
  get [AlcedoTypes.RADIATION_EQUIVALENT_DOSE_RATE]() {
    return '';
  },
  get [AlcedoTypes.A_WEIGHTING]() {
    return '';
  },
  get [AlcedoTypes.TURBIDITY]() {
    return '';
  },
  get [AlcedoTypes.ACIDITY]() {
    return '';
  },
  get [AlcedoTypes.AREA_DENSITY]() {
    return '';
  },
  get [AlcedoTypes.HEATING_RATE]() {
    return '';
  },
  get [AlcedoTypes.ELECTRIC_CHARGE]() {
    return '';
  },
  get [AlcedoTypes.AUXILIARY_ENERGY_1]() {
    return '';
  },
  get [AlcedoTypes.AUXILIARY_ENERGY_2]() {
    return '';
  },
  get [AlcedoTypes.VISCOSITY]() {
    return '';
  },
  get [AlcedoTypes.ENERGY_DENSITY]() {
    return '';
  },
  get [AlcedoTypes.EMISSION_COEFFICIENT]() {
    return '';
  }
};
