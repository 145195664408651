export enum AlcedoUnit {
  UNIT_NOT_SET = -1,
  SQUARE_METERS = 0,
  SQUARE_FEET = 1,
  MILLIAMPERES = 2,
  AMPERES = 3,
  OHMS = 4,
  VOLTS = 5,
  KILOVOLTS = 6,
  MEGAVOLTS = 7,
  VOLT_AMPERES = 8,
  KILOVOLT_AMPERES = 9,
  MEGAVOLT_AMPERES = 10,
  VOLT_AMPERES_REACTIVE = 11,
  KILOVOLT_AMPERES_REACTIVE = 12,
  MEGAVOLT_AMPERES_REACTIVE = 13,
  DEGREES_PHASE = 14,
  POWER_FACTOR = 15,
  JOULES = 16,
  KILOJOULES = 17,
  WATT_HOURS = 18,
  KILOWATT_HOURS = 19,
  BTUS = 20,
  THERMS = 21,
  TON_HOURS = 22,
  JOULES_PER_KILOGRAM_DRY_AIR = 23,
  BTUS_PER_POUND_DRY_AIR = 24,
  CYCLES_PER_HOUR = 25,
  CYCLES_PER_MINUTE = 26,
  HERTZ = 27,
  GRAMS_OF_WATER_PER_KILOGRAM_DRY_AIR = 28,
  PERCENT_RELATIVE_HUMIDITY = 29,
  MILLIMETERS = 30,
  METERS = 31,
  INCHES = 32,
  FEET = 33,
  WATTS_PER_SQUARE_FOOT = 34,
  WATTS_PER_SQUARE_METER = 35,
  LUMENS = 36,
  LUXES = 37,
  FOOT_CANDLES = 38,
  KILOGRAMS = 39,
  POUNDS_MASS = 40,
  TONS = 41,
  KILOGRAMS_PER_SECOND = 42,
  KILOGRAMS_PER_MINUTE = 43,
  KILOGRAMS_PER_HOUR = 44,
  POUNDS_MASS_PER_MINUTE = 45,
  POUNDS_MASS_PER_HOUR = 46,
  WATTS = 47,
  KILOWATTS = 48,
  MEGAWATTS = 49,
  BTUS_PER_HOUR = 50,
  HORSEPOWER = 51,
  TONS_REFRIGERATION = 52,
  PASCALS = 53,
  KILOPASCALS = 54,
  BARS = 55,
  POUNDS_FORCE_PER_SQUARE_INCH = 56,
  CENTIMETERS_OF_WATER = 57,
  INCHES_OF_WATER = 58,
  MILLIMETERS_OF_MERCURY = 59,
  CENTIMETERS_OF_MERCURY = 60,
  INCHES_OF_MERCURY = 61,
  DEGREES_CELSIUS = 62,
  KELVIN = 63,
  DEGREES_FAHRENHEIT = 64,
  DEGREE_DAYS_CELSIUS = 65,
  DEGREE_DAYS_FAHRENHEIT = 66,
  YEARS = 67,
  MONTHS = 68,
  WEEKS = 69,
  DAYS = 70,
  HOURS = 71,
  MINUTES = 72,
  SECONDS = 73,
  METERS_PER_SECOND = 74,
  KILOMETERS_PER_HOUR = 75,
  FEET_PER_SECOND = 76,
  FEET_PER_MINUTE = 77,
  MILES_PER_HOUR = 78,
  CUBIC_FEET = 79,
  CUBIC_METERS = 80,
  IMPERIAL_GALLONS = 81,
  LITERS = 82,
  US_GALLONS = 83,
  CUBIC_FEET_PER_MINUTE = 84,
  CUBIC_METERS_PER_SECOND = 85,
  IMPERIAL_GALLONS_PER_MINUTE = 86,
  LITERS_PER_SECOND = 87,
  LITERS_PER_MINUTE = 88,
  US_GALLONS_PER_MINUTE = 89,
  DEGREES_ANGULAR = 90,
  DEGREES_CELSIUS_PER_HOUR = 91,
  DEGREES_CELSIUS_PER_MINUTE = 92,
  DEGREES_FAHRENHEIT_PER_HOUR = 93,
  DEGREES_FAHRENHEIT_PER_MINUTE = 94,
  NO_UNIT = 95,
  PARTS_PER_MILLION = 96,
  PARTS_PER_BILLION = 97,
  PERCENT = 98,
  PERCENT_PER_SECOND = 99,
  PER_MINUTE = 100,
  PER_SECOND = 101,
  PSI_PER_DEGREE_FAHRENHEIT = 102,
  RADIANS = 103,
  REVOLUTIONS_PER_MINUTE = 104,
  CURRENCY1 = 105,
  CURRENCY2 = 106,
  CURRENCY3 = 107,
  CURRENCY4 = 108,
  CURRENCY5 = 109,
  CURRENCY6 = 110,
  CURRENCY7 = 111,
  CURRENCY8 = 112,
  CURRENCY9 = 113,
  CURRENCY10 = 114,
  SQUARE_INCHES = 115,
  SQUARE_CENTIMETERS = 116,
  BTUS_PER_POUND = 117,
  CENTIMETERS = 118,
  POUNDS_MASS_PER_SECOND = 119,
  DELTA_DEGREES_FAHRENHEIT = 120,
  DELTA_KELVIN = 121,
  KILOHMS = 122,
  MEGOHMS = 123,
  MILLIVOLTS = 124,
  KILOJOULES_PER_KILOGRAM = 125,
  MEGAJOULES = 126,
  JOULES_PER_KELVIN = 127,
  JOULES_PER_KILOGRAM_KELVIN = 128,
  KILOHERTZ = 129,
  MEGAHERTZ = 130,
  PER_HOUR = 131,
  MILLIWATTS = 132,
  HECTOPASCALS = 133,
  MILLIBARS = 134,
  CUBIC_METERS_PER_HOUR = 135,
  LITERS_PER_HOUR = 136,
  KILOWATT_HOURS_PER_SQUARE_METER = 137,
  KILOWATT_HOURS_PER_SQUARE_FOOT = 138,
  MEGAJOULES_PER_SQUARE_METER = 139,
  MEGAJOULES_PER_SQUARE_FOOT = 140,
  WATTS_PER_SQUARE_METER_KELVIN = 141,
  CUBIC_FEET_PER_SECOND = 142,
  PERCENT_OBSCURATION_PER_FOOT = 143,
  PERCENT_OBSCURATION_PER_METER = 144,
  MILLIOHMS = 145,
  MEGAWATT_HOURS = 146,
  KILO_BTUS = 147,
  MEGA_BTUS = 148,
  KILOJOULES_PER_KILOGRAM_DRY_AIR = 149,
  MEGAJOULES_PER_KILOGRAM_DRY_AIR = 150,
  KILOJOULES_PER_KELVIN = 151,
  MEGAJOULES_PER_KELVIN = 152,
  NEWTON = 153,
  GRAMS_PER_SECOND = 154,
  GRAMS_PER_MINUTE = 155,
  TONS_PER_HOUR = 156,
  KILO_BTUS_PER_HOUR = 157,
  HUNDREDTHS_SECONDS = 158,
  MILLISECONDS = 159,
  NEWTON_METERS = 160,
  MILLIMETERS_PER_SECOND = 161,
  MILLIMETERS_PER_MINUTE = 162,
  METERS_PER_MINUTE = 163,
  METERS_PER_HOUR = 164,
  CUBIC_METERS_PER_MINUTE = 165,
  METERS_PER_SECOND_PER_SECOND = 166,
  AMPERES_PER_METER = 167,
  AMPERES_PER_SQUARE_METER = 168,
  AMPERE_SQUARE_METERS = 169,
  FARADS = 170,
  HENRYS = 171,
  OHM_METERS = 172,
  SIEMENS = 173,
  SIEMENS_PER_METER = 174,
  TESLAS = 175,
  VOLTS_PER_KELVIN = 176,
  VOLTS_PER_METER = 177,
  WEBERS = 178,
  CANDELAS = 179,
  CANDELAS_PER_SQUARE_METER = 180,
  KELVIN_PER_HOUR = 181,
  KELVIN_PER_MINUTE = 182,
  JOULE_SECONDS = 183,
  RADIANS_PER_SECOND = 184,
  SQUARE_METERS_PER_NEWTON = 185,
  KILOGRAMS_PER_CUBIC_METER = 186,
  NEWTON_SECONDS = 187,
  NEWTONS_PER_METER = 188,
  WATTS_PER_METER_PER_KELVIN = 189,
  MICROSIEMENS = 190,
  CUBIC_FEET_PER_HOUR = 191,
  US_GALLONS_PER_HOUR = 192,
  KILOMETERS = 193,
  MICROMETERS = 194,
  GRAMS = 195,
  MILLIGRAMS = 196,
  MILLILITERS = 197,
  MILLILITERS_PER_SECOND = 198,
  DECIBELS = 199,
  DECIBELS_MILLIVOLT = 200,
  DECIBELS_VOLT = 201,
  MILLISIEMENS = 202,
  WATT_HOURS_REACTIVE = 203,
  KILOWATT_HOURS_REACTIVE = 204,
  MEGAWATT_HOURS_REACTIVE = 205,
  MILLIMETERS_OF_WATER = 206,
  PER_MILLE = 207,
  GRAMS_PER_GRAM = 208,
  KILOGRAMS_PER_KILOGRAM = 209,
  GRAMS_PER_KILOGRAM = 210,
  MILLIGRAMS_PER_GRAM = 211,
  MILLIGRAMS_PER_KILOGRAM = 212,
  GRAMS_PER_MILLILITER = 213,
  GRAMS_PER_LITER = 214,
  MILLIGRAMS_PER_LITER = 215,
  MICROGRAMS_PER_LITER = 216,
  GRAMS_PER_CUBIC_METER = 217,
  MILLIGRAMS_PER_CUBIC_METER = 218,
  MICROGRAMS_PER_CUBIC_METER = 219,
  NANOGRAMS_PER_CUBIC_METER = 220,
  GRAMS_PER_CUBIC_CENTIMETER = 221,
  BECQUERELS = 222,
  KILOBECQUERELS = 223,
  MEGABECQUERELS = 224,
  GRAY = 225,
  MILLIGRAY = 226,
  MICROGRAY = 227,
  SIEVERTS = 228,
  MILLISIEVERTS = 229,
  MICROSIEVERTS = 230,
  MICROSIEVERTS_PER_HOUR = 231,
  DECIBELS_A = 232,
  NEPHELOMETRIC_TURBIDITY_UNIT = 233,
  PH = 234,
  GRAMS_PER_SQUARE_METER = 235,
  MINUTES_PER_KELVIN = 236,
  OHM_METER_SQUARED_PER_METER = 237,
  AMPERE_SECONDS = 238,
  VOLT_AMPERE_HOURS = 239,
  KILOVOLT_AMPERE_HOURS = 240,
  MEGAVOLT_AMPERE_HOURS = 241,
  VOLT_AMPERE_HOURS_REACTIVE = 242,
  KILOVOLT_AMPERE_HOURS_REACTIVE = 243,
  MEGAVOLT_AMPERE_HOURS_REACTIVE = 244,
  VOLT_SQUARE_HOURS = 245,
  AMPERE_SQUARE_HOURS = 246,
  JOULES_PER_HOUR = 247,
  CUBIC_FEET_PER_DAY = 248,
  CUBIC_METERS_PER_DAY = 249,
  WATT_HOURS_PER_CUBIC_METER = 250,
  JOULES_PER_CUBIC_METER = 251,
  MOLE_PERCENT = 252,
  PASCAL_SECONDS = 253,
  MILLION_STANDARD_CUBIC_FEET_PER_MINUTE = 254,
  BYTE_PER_SECOND = 256,
  KILOBYTE_PER_SECOND = 257,
  MEGABYTE_PER_SECOND = 258,
  BYTE = 259,
  KILOBYTE = 260,
  MEGABYTE = 261,
  GIGABYTE = 262,
  TERABYTE = 263,
  KIBIBYTE = 264,
  MEBIBYTE = 265,
  GIBIBYTE = 266,
  TEBIBYTE = 267,
  GIGAJOULE = 268,
  GIGAHERTZ = 269,
  TERAHERTZ = 270,
  PER_DAY = 271,
  WATT_HOURS_PER_SQUARE_METER = 272,
  MILLIJOULES_PER_SECOND = 273,
  JOULES_PER_SECOND = 274,
  KILOJOULES_PER_SECOND = 275,
  MEGAJOULES_PER_SECOND = 276,
  GIGAJOULES_PER_SECOND = 277,
  MILLIJOULES_PER_HOUR = 278,
  KILOJOULES_PER_HOUR = 280,
  MEGAJOULES_PER_HOUR = 281,
  GIGAJOULES_PER_HOUR = 282,
  DECIBELS_MILLIWATT = 283,
  DECIBELS_WATT = 284,
  JOULES_PER_KILOGRAM = 285,
  MEGAJOULES_PER_KILOGRAM = 286,
  CENTIGRADE_DELTA = 287,
  UNIX_TIMESTAMP = 288,
  MILLILITERS_PER_HOUR = 290,
  GIGAWATT_HOURS = 295,
  KILOGRAMS_PER_SQUARE_METER = 296,
  MICROAMPERES = 297,
  KILOWATT_HOURS_PER_KILOGRAM = 300,
  KILOWATT_HOURS_PER_CUBIC_METER = 301,
  KILOGRAMS_PER_JOULE = 302,
  KILOGRAMS_PER_KILOWATT_HOUR = 303,
  TONS_PER_KILOWATT_HOUR = 304,
  KILOLUXES = 305,
  REVOLUTIONS_PER_SECONDS = 306,
  CUSTOM = 1001,
  STANDARD_CUBIC_FEET_PER_DAY = 47808,
  MILLION_STANDARD_CUBIC_FEET_PER_DAY = 47809,
  THOUSAND_CUBIC_FEET_PER_DAY = 47810,
  THOUSAND_STANDARD_CUBIC_FEET_PER_DAY = 47811,
  POUNDS_MASS_PER_DAY = 47812,
  MILLIREMS = 47814,
  MILLIREMS_PER_HOUR = 47815,
  DEGREES_LOVIBOND = 47816,
  ALCOHOL_BY_VOLUME = 47817,
  INTERNATIONAL_BITTERING_UNITS = 47818,
  EUROPEAN_BITTERNESS_UNITS = 47819,
  DEGREES_PLATO = 47820,
  SPECIFIC_GRAVITY = 47821,
  EUROPEAN_BREWING_CONVENTION = 47822
}
